<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Даалгаврын сан</h5>
        <p class="text-body">
          Даалгаврын сан
        </p>
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-capitalize
            ms-auto
            btn-primary
            bg-gradient-primary
            py-3
            px-6
            ms-3
          "
          @click="_createAssignmentDatabase(null)"
          >Сан үүсгэx</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in assignments"
        :key="assignment.id"
      >
        <AssignmentCard
          :assignment="assignment"
          @click="_detail(assignment)"
        ></AssignmentCard>
      </v-col>
    </v-row>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ даалгаврын сан үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.shortName"
                  label="Товчилсон нэр"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.code" label="Код" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="btn-primary bg-gradient-primary" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentCard from "./AssignmentCard.vue";

export default {
  components: { AssignmentCard },
  data: () => ({
    messageNotification: null,
    newDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,
    assignments: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
  },
  created() {
    this.path = this.yyschool + "students";
    this._setupp();
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    saveItem() {
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        this.editedItem.createdAt = new Date();
        if (this.editedIndex === -1) {
          this.userData.ref
            .collection("assignmentsdatabase")
            .doc()
            .set(this.editedItem)
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      this.userData.ref.collection("assignmentsdatabase").onSnapshot((docs) => {
        this.assignments = [];
        docs.forEach((doc) => {
          let assign = doc.data();
          assign.id = doc.id;
          assign.ref = doc.ref;
          this.assignments.push(assign);
        });
      });
    },
  },
};
</script>
 